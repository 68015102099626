import React from 'react'
import { useReducer, useState } from 'react';
import Layout from '../Layout';
import { loginReducer, initialState } from "../../../context/login/LoginContext";

const AdminDashboard = () => {
  const [loginState, dispatch] = useReducer(loginReducer, initialState);
  return (
    <>
      <Layout>
        <div>Hi </div>
        </Layout> 
    </>
    
  )
}

export default AdminDashboard