import { createContext, useReducer } from "react";
// import loginReducer from "./loginReducer";
import {API_BASE_URL, LOGIN} from "../../configs/APIConfig";
import axios from 'axios';

export const initialState = {
	appToken: null,
  isAuthenticated: false,
  error_msg: null
};

export const loginReducer = (state, action) => {
	switch (action.type) {
		case "Login": {
      state.appToken = action.payload.appToken;
      state.isAuthenticated = true;
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.companyId = action.payload.companyId;
      //set these value to session storage
      sessionStorage.setItem("appToken", action.payload.appToken);
      sessionStorage.setItem("isAuthenticated", true);
      sessionStorage.setItem("id", action.payload.id);
      sessionStorage.setItem("role", action.payload.role);
      sessionStorage.setItem("email", action.payload.email);
      sessionStorage.setItem("companyId", action.payload.companyId);
			return {
				...state,
			};
		}

		case "ERROR": {
      const errorMsg = action.message;
			return {
				...state,
				isAuthenticated: false,
        error_msg: errorMsg
			};
		}
		default: {
			return { ...state };
		}
	}
};

export const LoginContext = createContext({
  ...initialState,
	Login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
})
export const LoginProvider = ({ children }) => {
  const [state, dispatch] = useReducer(loginReducer, initialState);

  const Login = async (values) => {
		//api call must be here
		let url = API_BASE_URL+LOGIN;
    let data = {
      'username' : values.email,
      'password': values.password,
    } 
		let response  = await axios.post(url, data, {headers: {'Content-Type': 'application/json'}});
    return response;
	}

  const logout = () => {
    sessionStorage.clear();
    setTimeout(() => { window.location.replace('/');  }, 500);
  }

  return (
    <LoginContext.Provider
      value={{
        ...state,
        Login,
        logout
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export default LoginContext;