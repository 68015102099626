import React from 'react'
import { useEffect, useState } from 'react';
import Layout from '../Layout';
import ListComponent from '../../../components/crud/ListComponent';
import { companyTableList } from '../../../utils/dataTableList/company';
import { GetCompanyList } from '../../../services/CompanyAPi';

const CompanyList = () => {
  const [data, setData] = useState([])
	useEffect(() => {
		async function fetchData() {
			const result = await GetCompanyList();
      console.log(result.length);
			setData(result);
		}
		fetchData();
	}, [])
  return (
    <>
        <Layout>
            <ListComponent 
                title= "Company"
                addLink={"/company/add"}
                rowData={data}
                tableColumnsList={companyTableList}
                rowHeight={'200px'}
            />
        </Layout>
    </>
  )
}

export default CompanyList