import React from 'react'
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { profilePassChange } from '../../services/ProfileApi';

const userAddSchema = 
  Yup.object({
    oldpass: Yup.string().required("Please enter old password."),
    newpass: Yup.string().required("Please enter new password."),
    confirmpass: Yup.string().oneOf([Yup.ref('newpass'), null], 'Passwords must match'),
  })

const ChangePasswordComponent = (props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      oldpass: "",
      newpass: "",
      confirmpass: "",
    },
    validationSchema: userAddSchema,
    onSubmit: values => {

      profilePassChange(values.oldpass, values.newpass).then((response) => {
        if(response.status === 'success'){
          //redirect to list page
          navigate('/dashboard');
        }else{
          if(response.errorCode === 'MISMATCH'){

          }
          setIsError(true);
          setErrorMsg(response.message);
        }
      })
    },
  });
  return (
    <>
        <Card className="mt-3">
        <CardHeader
            title= "Change Password"
        />
        <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          { isError ? (
            <Alert severity="error">{ errorMsg }</Alert>
          ) : ("")}
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Old Password *"
                    name="oldpass"
                    type="password"
                    value={formik.values.oldpass}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.oldpass && Boolean(formik.errors.oldpass)}
                    helperText={formik.touched.oldpass && formik.errors.oldpass}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="New Password *"
                        name="newpass"
                        type="password"
                        value={formik.values.newpass}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.newpass && Boolean(formik.errors.newpass)}
                        helperText={formik.touched.newpass && formik.errors.newpass}
                    />
                </Grid>
                <Grid  item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Confirm Password *"
                            name="confirmpass"
                            type="password"
                            value={formik.values.confirmpass}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.confirmpass && Boolean(formik.errors.confirmpass)}
                            helperText={formik.touched.confirmpass && formik.errors.confirmpass}
                        />
                </Grid>                
                <Grid  item xs={12}>
                    <Button variant="contained" size="large" type="Submit">
                      Change Password
                    </Button>
                </Grid>
            </Grid>
            </form>
        </Box>

        </CardContent>
        </Card>
    </>
  )
}

export default ChangePasswordComponent