import React from 'react'
import Layout from '../Layout';
import CompanyViewCustomers from '../../../components/company/CompanyViewCustomers';

const ViewCompanyCustomers = () => {
  return (
    <Layout>
      <CompanyViewCustomers />
    </Layout>
  )
}

export default ViewCompanyCustomers
