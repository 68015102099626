import React from 'react'
import { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AddDevice, ListDevice } from '../../services/DeviceApi';
import TableComponent from "./TableComponent";

const ListWithActionComponent = (props) => {
  const [open, setOpen] = React.useState(false);
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [callBack, setCallBack] = React.useState("");
  const [deviceList, setDeviceList] = React.useState([]);
  const [modelError, setModelError] = React.useState("");

  useEffect(() => {
		async function fetchData() {
			const response = await ListDevice();
			if(response.status === 'success'){
        setDeviceList(response.data);
      }
		}
		fetchData();
	}, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const submitModel = async (event) => {
    event.preventDefault();
    //console.log(mobileNumber);
    //console.log(callBack);
    await AddDevice(mobileNumber, callBack).then((response) => {
      if(response.data.status === 'success'){
        ListDevice().then((response)=>{
          if(response.status === 'success'){
            setDeviceList(response.data);
            window.location.reload();
            handleClose();

          }
        }).catch((error)=>{
          console.log(error);
          handleClose();
        })
      }else{
        // we have error which we need to show on model
        console.log(response.data.message);
        setModelError(response.data.message);
      }
      
    }).catch((error)=>{
      console.log(error);
      handleClose();
    })
    
  }

  const handleMobile = (e) => {
    const numRegex = /^[0-9]+$/;
    if (numRegex.test(e.target.value)) {
      if(e.target.value.length < 13){
        setMobileNumber(e.target.value);
      }
    }
  }
  return (
    <Card className="mt-3">
      <CardHeader
        title= {props.title+' List'}
      />
      <CardContent>
        <Button variant="outlined" onClick={handleClickOpen}>
          Add New Device
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {submitModel(event)},
          }}
        >
          <DialogTitle>Device Add</DialogTitle>
          <DialogContent>
            {
              modelError ?
              (<Alert severity="error">{modelError}</Alert>) : ("")
            }
            <DialogContentText>
              Add New Device for whats app message to be sent. Also please add number with 91 not +91
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="mobileNumber"
              label="Mobile Number"
              type="text"
              pattern="[0-9]*"
              fullWidth
              variant="standard"
              value={mobileNumber}
              onChange={handleMobile}
            />
            <TextField
              autoFocus
              margin="dense"
              id="url"
              name="callbackurl"
              label="Calback Url (Optional)"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => setCallBack(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add Device</Button>
          </DialogActions>
        </Dialog>
        <TableComponent rows={deviceList}/>
      </CardContent>
    </Card>
  )
}

export default ListWithActionComponent