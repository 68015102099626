import React from 'react'
import SideMenu from '../../utils/sidebar/SideMenu';
import Header from '../../utils/header/header';
import { Box } from '@mui/material';

const Layout = ({ children }) => {
  return (
    <>
      <div style={{ display: "flex", height: "100vh" }}>
          <SideMenu />
          <Box sx={{ flexGrow: 1 }}>
            <Header />
            <div style={{ margin: "20px"}}>
              { children}
            </div>
          </Box>
      </div>
    </>
  )
}

export default Layout