import React, { useState } from 'react'
import { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import { GetCompaniesCustomers } from '../../services/CompanyAPi';
import { GetApiKey, GenerateNewApiKey } from '../../services/CutomerApi';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import ApiKeyModel from '../crud/models/ApiKeyModel';
import ConfirmModel from '../crud/models/ConfirmModel';

const CompanyViewCustomers = () => {
  const { companyId } = useParams();
  const [ listData, setListData ] = useState([]); 
  const [ apiKeyOpen, setApiKeyOpen ] = useState(false);
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const [ apiKey, setApiKey ] = useState("");
  const [ selectedCustomerId, setSelectedCustomerId ] = useState("");

  useEffect(() => {
		async function fetchData() {
      const result = await GetCompaniesCustomers(companyId);
      if(result.status === 'success'){
        setListData(result.data)
      }
		}
		fetchData();
	}, [])

  const viewApiKey = async (customerId) => {
    console.log(customerId);
    const result = await GetApiKey(customerId);
    
    if(result.status === 'success'){
      setApiKey(result.data.apiKey)
    }else{
      // set error msg on the screen
    }
    setApiKeyOpen(true);
  }

  const generateApiKey = async (customerId) => {
    setSelectedCustomerId(customerId)
    setConfirmOpen(true);
  }

  const afterConfirmMethod = async (customerId)=>{
    //call generate APi key function
    const response = await GenerateNewApiKey(customerId, companyId);
    if(response.status === 'success'){
      //console.log(customerId);
      setSelectedCustomerId("");
      viewApiKey(customerId);
    }else{
      // promt error
    }
    
  }
  return (
    <Card className="mt-3">
      <CardHeader
        title= {'View Company Customer List'}
      />
      <CardContent>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Max Devices</TableCell>
                    <TableCell>Validity</TableCell>
                    <TableCell>Credit</TableCell>
                    <TableCell>Api Key</TableCell>
                    <TableCell>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {
                listData.map((row)=>(
                  <TableRow key={row.id}>
                    <TableCell>{ row.firstName+' '+row.lastName }</TableCell>
                    <TableCell>{ row.mobileNumber }</TableCell>
                    <TableCell>{ row.maxDevices }</TableCell>
                    <TableCell>{ row.validity }</TableCell>
                    <TableCell>{ row.credit }</TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={2}>
                        <Button onClick={()=>{viewApiKey(row.uuid)}} variant="outlined" color="warning" size="small">View API Key</Button>
                        <Button onClick={()=>{generateApiKey(row.uuid)}} variant="outlined" color="warning" size="small">Generate API Key</Button>
                      </Stack>
                    </TableCell>
                    <TableCell>{ row.status === true ? 'Active': 'Inactive' }</TableCell>
                </TableRow>
                ))
              }
            </TableBody>
            </Table>
            <ApiKeyModel apiKeyOpen={apiKeyOpen} setApiKeyOpen={setApiKeyOpen} apiKey={apiKey}/>
            <ConfirmModel confirmMessage={'Are you sure you want to generate a new ApiKey?'}  confirmOpen={confirmOpen} setConfirmOpen={setConfirmOpen} confirmId={selectedCustomerId} afterConfirmMethod={afterConfirmMethod}/>
        </TableContainer>
    </CardContent>
    </Card>
  )
}

export default CompanyViewCustomers
