import axios from 'axios';
import { API_BASE_URL } from '../../configs/APIConfig';


export const AxiosGet = async (url) => {
  const apiUrl = url;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : "Bearer "+ sessionStorage.getItem('appToken'),
    }
  }
  //console.log(API_BASE_URL);
  const response = await axios.get(`${API_BASE_URL}${url}`, config);
  return response;
}

export const AxiosPut = async (url, data) => {
  const apiUrl = API_BASE_URL+url;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : "Bearer "+ sessionStorage.getItem('appToken'),
    }
  }
  const response = await axios.put(apiUrl, data, config);
  return response;
}

export const AxiosPost = async (url, data) => {
  const apiUrl = API_BASE_URL+url;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : "Bearer "+ sessionStorage.getItem('appToken'),
    }
  }
  const response = await axios.post(apiUrl, data, config);
  return response;
}

export const AxiosDelete = async (url) => {
  const apiUrl = API_BASE_URL+url;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : "Bearer "+ sessionStorage.getItem('appToken'),
    }
  }
  const response = await axios.delete(apiUrl, config);
  return response;
}