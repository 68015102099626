import React from 'react'
import Layout from '../Layout';
import SubscriptionComponent from '../../../components/customer/SubscriptionComponent';

const Subscription = () => {
  
  return (
    <Layout>
      <SubscriptionComponent />
    </Layout>
  )
}

export default Subscription
