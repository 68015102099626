import { AxiosPost } from "../utils/axios/AxiosCall";
import { CHANGE_PASS_URL } from "../configs/APIConfig";
const { Base64 } = require("js-base64");

/***
 *  This method is for change password
 */
export const profilePassChange = async (old, newphase) => {
  try {
    const postData = {
      oldpass: Base64.encode(old),
      newpass: Base64.encode(newphase)
    }
    const result = await AxiosPost(CHANGE_PASS_URL.replace('[id]', sessionStorage.getItem('id')), postData);
    if(result.status === 200){
			//console.log(result);
			return result.data;
		}else{
			return result;
		}
  } catch (error) {
    console.log(error.message);
		return false;
  }
}
