import { AxiosGet, AxiosPost, AxiosPut, AxiosDelete } from "../utils/axios/AxiosCall";
import { 
  CUSTOMER_URL, 
  CUSTOMER_SUBSCRIPTION_URL, 
  CUSTOMER_API_KEY_GEN_URL,
  CUSTOMER_DETAILS_URL 
} from "../configs/APIConfig";

/***
 * This method will fetch list of customer only
 */
export const GetCustomerList = async () => {
  try {
    const result = await AxiosGet(
      CUSTOMER_URL + "/" + sessionStorage.getItem("companyId")
    );
    if (result.status === 200) {
      return result.data.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

/***
 * This method will create a new customer
 */
export const CreateNewCustomer = async (values) => {
  try {
    values.userType = "CUSTOMER";
    values.companyId = sessionStorage.getItem("companyId");
    const result = await AxiosPost(CUSTOMER_URL, values);
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

/***
 * This method will edit customer
 */
export const EditCustomer = async (values) => {
  try {
    values.userType = "CUSTOMER";
    values.companyId = sessionStorage.getItem("companyId");
    const result = await AxiosPut(CUSTOMER_URL, values);
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

/***
 * This method will delete customer
 */
export const DeleteCustomer = async (values) => {
  try {
    const result = await AxiosDelete(CUSTOMER_URL, values);
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

/****
 *
 */
export const GetCustomerSubscription = async (customerId) => {
  try {
    const result = await AxiosGet(
      CUSTOMER_SUBSCRIPTION_URL.replace("[customerId]", customerId)
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const UpdateCustomerSubscription = async (customerId, details) => {
  try {
    const result = await AxiosPost(
      CUSTOMER_SUBSCRIPTION_URL.replace("[customerId]", customerId),
      details
    );
    console.log(result);
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const GenerateNewApiKey = async (customerId, companyId) => {
  try {
    const result = await AxiosPost(
      CUSTOMER_API_KEY_GEN_URL.replace("[customerId]", customerId),
      { companyId: companyId }
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const GetApiKey = async (customerId) =>{
  try {
    const result = await AxiosGet(
      CUSTOMER_API_KEY_GEN_URL.replace("[customerId]", customerId)
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
}

export const GetCustomerDetails = async (id) => {
  try {
    const result = await AxiosGet(
      CUSTOMER_DETAILS_URL.replace("[customerId]", id)
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
}