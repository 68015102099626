import { AxiosGet, AxiosPost } from "../utils/axios/AxiosCall";
import { ADMIN_URL } from "../configs/APIConfig";

const appToken = sessionStorage.getItem('appToken');

/***
 * THis method will fetch list of company only
 */
export const GetAdminList = async () => {
	try {
		const result = await AxiosGet(ADMIN_URL);
		if(result.status === 200){
			return result.data;
		}else{
			return result;
		}
	} catch (error) {
		console.log(error.message);
		return false;
	}
}

/***
 * This method will create a new company
 */
export const CreateNewAdmin = async (values) => {
	try {
		values.userType = 'Admin';
		const result = await AxiosPost(ADMIN_URL, values);
		if(result.status === 200){
			return result.data;
		}else{
			return result;
		}
	} catch (error) {
		console.log(error.message);
		return false;
	}
}