import React from 'react'
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import AnalyticsCard from '../basic/AnalyticsCard';
import {GetCustomerSubscription} from "../../services/CutomerApi";

const ListDevices = () => {
  const [subscription, setSubscription] = useState()
	useEffect(() => {
		async function fetchData() {
			const result = await GetCustomerSubscription(sessionStorage.getItem("id"));
			setSubscription(result.data);
		}
		fetchData();
	}, [])  
  return (
    <Grid container spacing={4}>
      { subscription ? (
        <>
          <Grid item xs={4}>
            <AnalyticsCard title={'Credit'} value={subscription[0]['credit']}/>
          </Grid>
          <Grid item xs={4}>
            <AnalyticsCard title={'Validity'} value={subscription[0]['validity']+' days'}/>
          </Grid>
          <Grid item xs={4}>
            <AnalyticsCard title={'Devices'} value={subscription[0]['currentDevices']+" / "+subscription[0]['maxDevices']} />
          </Grid>
        </>
      ):("")
      } 
    </Grid>
  )
}

export default ListDevices
