import React from 'react'
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { GetCustomerSubscription, UpdateCustomerSubscription } from "../../services/CutomerApi";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const SubscriptionComponent = (props) => {
  const [openLoader, setOpenLoader] = useState(false);
  const [closeLoader, setCloseLoader] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const [ recieptError, setRecieptError ] = useState("");
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [current, setCurrent] = useState({
    maxDevices: "",
    credit: "",
    validity: "",
    receipt: ""
  })

  useEffect(() => {
		async function fetchData() {
      setOpenLoader(true)
      setCloseLoader(false)
			const result = await GetCustomerSubscription(id);
      if(result.status === 'success'){
        setData(result.data[0]);
      }
			
		}
		fetchData();
    setOpenLoader(false)
    setCloseLoader(true)
	}, [])

  const handleChangeText = (event) => {
    
    let name = event.target.name;
    let value = event.target.value;
    switch (name) {
      case 'maxDevices':
        setErrorMsg("")
        setCurrent({...current, maxDevices : value});
        break;
      case 'credit':
        setErrorMsg("")
        setCurrent({...current, credit : value});
        break;
      case 'validity':
        setErrorMsg("")
        setCurrent({...current, validity : value});
        break;
      case 'receipt':
        setRecieptError("");
        setCurrent({...current, receipt : value});
        break;
      default:
        break;
    }
    
  }

  const handleSubmit = () => {

    if(current.receipt === ""){
      setRecieptError("Please enter receipt number");
      return false;
    }

    if(current.credit === "" && current.validity === ""  && current.maxDevices === ""){
      setErrorMsg("Please update atleast one field.");
      return false;
    }

    current.companyId = sessionStorage.getItem("companyId");
    let postData = current;
    // if everything goes well
    UpdateCustomerSubscription(id, postData).then((response) => {
      setOpenLoader(true)
      setCloseLoader(false)
      if(response.status === 'success'){
        window.location.reload();
      }else{
        setErrorMsg(response.message);
      }
      setOpenLoader(false)
      setCloseLoader(true)
    }).catch((error) => {
      console.log(error);
    })
    
  }
  const shareWarning = () => {
    console.log("Are you sure you want to edit maxDevices?");
  }
  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openLoader}
        close={closeLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <Card className="mt-3">
        <CardHeader
            title= "Customer Subscription"
        />
        <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Card className="mt-3">
              <CardHeader
                    title= "Customer Details"
                />
              <CardContent>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Name
                      </TableCell>
                      <TableCell>
                        {data.firstName+" "+ data.lastName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Email
                      </TableCell>
                      <TableCell>
                        {data.emailId}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Mobile
                      </TableCell>
                      <TableCell>
                        {data.mobileNumber}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Max Devices
                      </TableCell>
                      <TableCell>
                        {data.maxDevices}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Current Devices
                      </TableCell>
                      <TableCell>
                        {data.currentDevices}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Credit
                      </TableCell>
                      <TableCell>
                        {data.credit}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        validity
                      </TableCell>
                      <TableCell>
                        {data.validity}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
              </Card>
            </Grid>
            <Grid item xs={9}>
              <Card className="mt-3">
                <CardHeader
                    title= "Customer Subspription"
                />
                <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Max Devices"
                            name="maxDevices"
                            value={current.maxDevices}
                            onChange={handleChangeText}
                            error={ Boolean(errorMsg)}
                            helperText={errorMsg}
                            onBlur={shareWarning}
                            />
                          <Alert severity="warning">If max devices reduces then user need to add all devices again!</Alert>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Credit ( 1 Credit = 1 whatsapp message)"
                            name="credit"
                            value={current.credit}
                            onChange={handleChangeText}
                            error={Boolean(errorMsg)}
                            helperText={errorMsg}
                            />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Validity ( in days)"
                            name="validity"
                            value={current.validity}
                            onChange={handleChangeText}
                            error={ Boolean(errorMsg)}
                            helperText={errorMsg}
                            />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Receipt *"
                            name="receipt"
                            value={current.receipt}
                            onChange={handleChangeText}
                            error={ Boolean(recieptError)}
                            helperText={recieptError}
                            />
                      </Grid>
                      <Grid  item xs={12}>
                        <Button variant="contained" size="large" type="Submit" onClick={handleSubmit}>
                          Update Subscription
                        </Button>
                      </Grid>
                    </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        </CardContent>
        </Card>
    </>
  )
}

export default SubscriptionComponent