export const  adminTableList = [
    { field: 'id', headerName: 'S.No', width: 50 },
    { field: "firstName", headerName: 'Admin Name', valueGetter: (value, row) => {
        return `${row.firstName || ''} ${row.lastName || ''}`;
      }, width: 250 },
    { field: 'mobileNumber', headerName: 'Mobile Number', width: 150 },
    { field: 'emailId', headerName: 'Email Id', width: 250 },
    { headerName: 'Status', valueGetter: (value, row) => {
        return `${row.status === true ? "Active" : 'Inactive'}`;
      }, width: 150 },
];