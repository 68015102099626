import React from "react";
import { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import { GetApiKey} from "../../services/CutomerApi";

const ApiSettingComponent = () => {
  const [openLoader, setOpenLoader] = useState(false);
  const [closeLoader, setCloseLoader] = useState("true");
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    setOpenLoader(true)
    setCloseLoader(false)
		async function fetchData() {
			const result = await GetApiKey(sessionStorage.getItem("id"));
      if(result.status === 'success'){
        setApiKey(result.data.apiKey);
      }
		}
		fetchData();
    setOpenLoader(false)
    setCloseLoader("true")
	}, [])
  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openLoader}
        close={closeLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardHeader title="Api Settings"/>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={2}> </Grid>
            <Grid item xs={8}> 
              <Card>
                <CardHeader title="Generate Key"/>
                <CardContent>
                <Grid item xs={12}> 
                <Grid item xs={12}>
                  <TextField
                      margin="normal"
                      fullWidth
                      label="ApiKey"
                      name="ApiKey"
                      value={apiKey}
                      disabled={true}
                      />
                </Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}> </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default ApiSettingComponent;
