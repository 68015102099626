import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SendWhatsApp } from '../../services/DeviceApi';
import Alert from '@mui/material/Alert';

export default function SendMessageModel(props) {
  const [recieverNumber, setRecieverNumber] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [modelError, setModelError] = React.useState("");

  const handleClose = () => {
    props.setSendOpen(false);
  };

  const submitModel = async (event) => {
    event.preventDefault();
    console.log(message);
    await SendWhatsApp(props.mobile, recieverNumber, message).then((response) => {
      //console.log(response);
      if(response.status === 'success'){
        handleClose();
      }else{
        // we have error which we need to show on model
        //console.log(response.message);
        setModelError(response.message);
      }
      
    }).catch((error)=>{
      console.log(error);
      handleClose();
    })
    
  }

  const handleMobile = (e) => {
    const numRegex = /^[0-9]+$/;
    if (numRegex.test(e.target.value)) {
      if(e.target.value.length < 13){
        setRecieverNumber(e.target.value);
      }
    }
  }

  return (
    <React.Fragment>
      <Dialog
          open={props.sendOpen}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => { submitModel(event) },
          }}
        >
          <DialogTitle>Send Message</DialogTitle>
          <DialogContent>
            {
              modelError ?
              (<Alert severity="error">{modelError}</Alert>) : ("")
            }
            <DialogContentText>
              Send message to the number. 
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="recieverNumber"
              label="Send to mobile number"
              type="text"
              pattern="[0-9]*"
              fullWidth
              variant="standard"
              value={recieverNumber}
              onChange={(e)=>{handleMobile(e)}}
            />
            <TextField
              required
              margin="dense"
              id="message"
              name="message"
              label="Message"
              type="text"
              fullWidth
              variant="standard"
              multiline
              maxRows={6}
              onChange={(e) => setMessage(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Send Message</Button>
          </DialogActions>
        </Dialog>
    </React.Fragment>
  );
}
