import React from 'react'
import { useEffect, useState } from 'react';
import Layout from '../Layout';
import ListComponent from '../../../components/crud/ListComponent';
import { OutLogsTableList } from '../../../utils/dataTableList/outlogs';
import { GetOutLogs } from '../../../services/DeviceApi';
import { useParams } from "react-router-dom";

const DeviceOutLogs = () => {
  let { mob } = useParams();
  const [data, setData] = useState([])
	useEffect(() => {
		async function fetchData() {
			const result = await GetOutLogs(mob);
			setData(result.data);
		}
		fetchData();
	}, [])

  return (
    <>
        <Layout>
            <ListComponent 
                title= "Send Message Logs"
                addLink={""}
                rowData={data}
                tableColumnsList={OutLogsTableList}
            />
        </Layout>
    </>
  )
}

export default DeviceOutLogs
