//import { useContext } from "react";
import { Navigate } from "react-router-dom";
//import { initialState, loginReducer } from "../context/login/LoginContext";
//import loginReducer from "../context/login/loginReducer";
//import LoginContext from "../context/login/LoginContext";
//import { cookies } from "../context/login/LoginContext";


export const ProtectedRoute = ({ children }) => {
  //const state = useContext(LoginContext);
  const auth = sessionStorage.getItem('isAutenticated');
  const appToken = sessionStorage.getItem('appToken');
  if (!auth && !appToken) {

    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};