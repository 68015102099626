// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #ffffff;
  font-size: 14px;
}
.app {
  background: #1976d2;
}
h1 {
  color: #165a72;
  margin: 50px auto;
  font-size: 12px;
}
.menu1 {
  margin-bottom: 40px;
  margin-top: 20px;
}
h2 {
  color: #165a72;
}
.akau {
  position: relative;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    width: 264px;
    height: 264px;
    overflow: hidden;
    border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/asset/css/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,0KAA0K;EAC1K,mCAAmC;EACnC,kCAAkC;EAClC,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;IAChB,aAAa;IACb,UAAU;IACV,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Figtree\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Figtree\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  height: 100vh;\n  background: #ffffff;\n  font-size: 14px;\n}\n.app {\n  background: #1976d2;\n}\nh1 {\n  color: #165a72;\n  margin: 50px auto;\n  font-size: 12px;\n}\n.menu1 {\n  margin-bottom: 40px;\n  margin-top: 20px;\n}\nh2 {\n  color: #165a72;\n}\n.akau {\n  position: relative;\n    display: flex;\n    flex: none;\n    align-items: center;\n    justify-content: center;\n    width: 264px;\n    height: 264px;\n    overflow: hidden;\n    border-radius: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
