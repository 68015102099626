import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmModel = (props) => {

  const handleClose = () => {
    props.setConfirmOpen(false);
  };

  return (
    <>
      <Dialog
        open={props.confirmOpen}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            props.afterConfirmMethod(props.confirmId);
            /*
            DeleteDevice(props.deleteMob)
            props.setDeleteMob("");
            window.location.reload();
            */
            handleClose();
            
          },
        }}
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.confirmMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmModel;

