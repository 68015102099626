import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { EditDevice } from "../../../services/DeviceApi";

const EditDeviceModel = (props) => {

  const handleClose = () => {
    props.setEditOpen(false);
  };

  const submitModel = async (event) => {
    event.preventDefault();

    await EditDevice(props.mob, props.backUrl).then((response) => {
      if(response.status === 'success'){
        handleClose();
        // refresh page
        window.location.reload();
      }else{
        // we have error which we need to show on model
        console.log(response.data.message);
        //setModelError(response.data.message);
      }
      
    }).catch((error)=>{
      console.log(error);
      handleClose();
    })
      
    
  }

  const handleMobile = (e) => {
    const numRegex = /^[0-9]+$/;
    if (numRegex.test(e.target.value)) {
      if(e.target.value.length < 13){
        //setRecieverNumber(e.target.value);
      }
    }
  }

  return (
    <React.Fragment>
      <Dialog
          open={props.editOpen}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => { submitModel(event) },
          }}
        >
          <DialogTitle>Edit Device</DialogTitle>
          <DialogContent>
            
            <DialogContentText>
              Edit Device 
            </DialogContentText>
            <TextField
              required
              margin="dense"
              id="name"
              name="mobileNumber"
              label="Mobile Number"
              type="text"
              pattern="[0-9]*"
              fullWidth
              variant="standard"
              value={props.mob}
              disabled={true}
            />
            <TextField
              autoFocus
              margin="dense"
              id="url"
              name="callbackurl"
              label="Calback Url (Optional)"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => props.setBackUrl(e.target.value)}
              value={props.backUrl}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Edit Device</Button>
          </DialogActions>
        </Dialog>
    </React.Fragment>
  );
}

export default EditDeviceModel
