import React from 'react'
import Layout from '../Layout';
import AddUserComponent from '../../../components/crud/AddUserComponent';

const CustomerAdd = () => {
  return (
    <Layout>
        <AddUserComponent title={"Add Customer"} userType={"Customer"}/>
    </Layout>
  )
}

export default CustomerAdd