import React from 'react'
import { useEffect, useState } from 'react';
import Layout from '../Layout';
import ListComponent from '../../../components/crud/ListComponent';
import {adminTableList} from "../../../utils/dataTableList/admin";
import { GetAdminList } from '../../../services/AdminAPi';

const AdminList = () => {
  const [data, setData] = useState([])
	useEffect(() => {
		async function fetchData() {
			const result = await GetAdminList();
      console.log(result.data);
      console.log(result.length);
			setData(result.data);
		}
		fetchData();
	}, [])
  return (
    <>
        <Layout>
            <ListComponent 
                title= "Admin"
                addLink={"/admin/add"}
                rowData={data}
                tableColumnsList={adminTableList}
                rowHeight={'200px'}
            />
        </Layout>
    </>
  )
}

export default AdminList