import React from 'react'
import Layout from '../Layout'
import BulkMessagesComponent from "../../../components/customer/BulkMessagesComponent"

const BulkMessages = () => {
  return (
    <Layout>
      <BulkMessagesComponent/>
    </Layout>
  )
}

export default BulkMessages
