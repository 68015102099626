export const  InLogsTableList = [
  { field: 'mobileNumber', headerName: 'Mobile Number', width: 150 },
  { field: 'msgId', headerName: 'Message Id', width: 250 },
  { field: 'from', headerName: 'From', width: 200 },
  { field: 'to', headerName: 'To', width: 150 },
  { field: 'message', headerName: 'Message', width: 450 },
  { headerName: 'Time', valueGetter: (value, row) => {
    return new Date(row.createdAt);
  }, width: 350 },
];

//new Date(utcDate)