import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const ApiKeyModel = (props) => {

  const handleClose = () => {
    props.setApiKeyOpen(false);
  };
  return (
    <React.Fragment>
      <Dialog
          open={props.apiKeyOpen}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => { //will add later
               },
          }}
        >
          <DialogTitle>View Customer Api Key</DialogTitle>
          <DialogContent>
            
            <DialogContentText>
              View Or Generate Customer Api Key 
            </DialogContentText>
            <TextField
              required
              margin="dense"
              id="name"
              name="ApiKey"
              label="API Kay"
              type="text"
              pattern="[0-9]*"
              fullWidth
              variant="standard"
              value={props.apiKey}
              disabled={true}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
    </React.Fragment>
  );
}

export default ApiKeyModel
