import React from 'react'
import Layout from '../Layout';
import AddUserComponent from '../../../components/crud/AddUserComponent';

const CompanyAdd = () => {
  return (
    <Layout>
        <AddUserComponent title={"Add Company"} userType={"Company"} isCompany={true}/>
    </Layout>
  )
}

export default CompanyAdd