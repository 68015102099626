import React from 'react'
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';


const ListComponent = (props) => {
  return (
    <Card className="mt-3">
      <CardHeader
        title= {props.title+' List'}
      />
      <CardContent>
        {
          props.addLink !== "" ? (<Link to={props.addLink}><Button variant="contained" size="large" sx={{ justifyContent: "space-between", mb: 2 }}>+ Add {props.title}</Button></Link>) : ("")
        }
        
      { props.rowData.length >= 0 ? (
      <DataGrid
        rows={props.rowData}
        columns={props.tableColumnsList}
        getRowHeight={(params) => props.rowHeight}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      /> ) : (<Box sx={{ flexGrow: 1 }}>No Record Found</Box>)}
      </CardContent>
    </Card>
  )
}

export default ListComponent