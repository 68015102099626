import { AxiosGet, AxiosPost } from "../utils/axios/AxiosCall";
import { COMPANY_URL, COMPANY_CUSTOMER_LIST_URL } from "../configs/APIConfig";

/***
 * THis method will fetch list of company only
 */
export const GetCompanyList = async () => {
	try {
		const result = await AxiosGet(COMPANY_URL);
		if(result.status === 200){
			return result.data.data;
		}else{
			return result;
		}
	} catch (error) {
		console.log(error.message);
		return false;
	}
}


/***
 * This method will create a new company
 */
export const CreateNewCompany = async (values) => {
	try {
		values.userType = 'COMPANY';
		const result = await AxiosPost(COMPANY_URL, values);
		if(result.status === 200){
			return result.data;
		}else{
			return result.message;
		}
	} catch (error) {
		console.log(error);
		return error;
	}
}

export const GetCompaniesCustomers = async (companyId) => {
	try {
		const result = await AxiosGet(COMPANY_CUSTOMER_LIST_URL.replace("[companyId]", companyId));
		if(result.status === 200){
			return result.data;
		}else{
			return result.message;
		}
	} catch (error) {
		console.log(error);
		return error;
	}
}