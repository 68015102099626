import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import ChatIcon from '@mui/icons-material/Chat';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import { Link } from "react-router-dom";
import ShoWQRCodeModel from './ShowQRCodeModel';
import { useState } from 'react';
import {ScanInitRequest, QrCodeRequest } from "../../services/DeviceApi";
import { useNavigate } from 'react-router-dom';
import SendMessageModel from './SendMessageModel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteModel from './models/DeleteModel';
import EditIcon from '@mui/icons-material/Edit';
import EditDeviceModel from './models/EditDeviceModel';

export default function TableComponent(props) {
  const [ showqr, setShowqr] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [closeLoader, setCloseLoader] = useState(true);
  const [ qr, setQr] = useState("");
  const [ mobile, setMobile] = useState("");
  const [ sendOpen, setSendOpen] = useState(false);
  const [ editOpen, setEditOpen] = useState(false);
  const [ backUrl, setBackUrl] = useState("");
  const [ deleteOpen, setDeleteOpen] = useState(false);
  const [ deleteMob, setDeleteMob] = useState("");
  const [ scanFor, setScanFor ] = useState("");
  const navigate = useNavigate();
  const handleQrCode = (mobile) =>{
    setOpenLoader(true)
    setCloseLoader(false)
    setScanFor(mobile)
    // create scan api call

    ScanInitRequest(mobile).then((response)=>{
      setOpenLoader(false)
      setCloseLoader(true)
      //console.log(response);
      setQr(response.data);
      setShowqr(true);
    }).catch((error)=>{
      console.log(error);
    })    
  }

  const viewLogs = (e, mob) =>{
    e.preventDefault();
    navigate('/device/in-logs/'+mob);
    return false;
  }

  const viewOutLogs = (e, mob) =>{
    e.preventDefault();
    navigate('/device/out-logs/'+mob);
    return false;
  }

  const sendMsg = (e, mob) => {
    e.preventDefault();
    setMobile(mob);
    setSendOpen(true);
  }

  const editDevice = (e, mob, backUrl) => {
    e.preventDefault();
    setMobile(mob);
    setBackUrl(backUrl);
    setEditOpen(true);
  }

  const deleteDevice = (e, mob) => {
    e.preventDefault();
    setDeleteMob(mob)
    setDeleteOpen(true)
  }
  return (
      <TableContainer component={Paper}>
        <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={openLoader}
          close={closeLoader}
        >
          <CircularProgress color="inherit" />
          <div> Loading...</div>
        </Backdrop>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Device Status</TableCell>
              <TableCell align="left">Callback Url</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.mobileNumber}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.deviceStatus === 'Ready'? 'Ready':'Please scan QR Code'}
                </TableCell>
                <TableCell align="left">{row.callbackUrl}</TableCell>
                <TableCell align="right">{row.status === true? <Chip label="Active" color="primary" variant="outlined" />: <Chip label="Inactive" color="error" variant="outlined" />}</TableCell>
                <TableCell align="center">
                  {
                    row.deviceStatus === 'Ready'? (
                      <>
                        <Link onClick={(e)=>{editDevice(e, row.mobileNumber, row.callbackUrl)}}><EditIcon/></Link>
                        <Link onClick={(e)=>{sendMsg(e, row.mobileNumber)}}><SendToMobileIcon/></Link>
                        <Link onClick={(e)=>{viewLogs(e, row.mobileNumber)}}><AssignmentIcon/></Link>
                        <Link onClick={(e)=>{viewOutLogs(e, row.mobileNumber)}}><ChatIcon/></Link>
                      </>
                    ):(
                      <Link onClick={()=>{handleQrCode(row.mobileNumber)}}><QrCode2Icon/></Link>
                    )
                  }
                  <Link onClick={(e)=>{deleteDevice(e, row.mobileNumber)}}><DeleteForeverIcon/></Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ShoWQRCodeModel showqr={showqr} setShowqr={setShowqr} qrcode={qr} mobile={scanFor} />
        <SendMessageModel mobile={mobile} sendOpen= {sendOpen} setSendOpen={setSendOpen} />
        <DeleteModel deleteOpen={deleteOpen} setDeleteOpen={setDeleteOpen} setDeleteMob={setDeleteMob} deleteMob={deleteMob} />
        <EditDeviceModel editOpen={editOpen} setEditOpen={setEditOpen} mob={mobile}  backUrl={backUrl} setBackUrl={setBackUrl}/>
      </TableContainer>
    );
  }