import React from 'react'
import Layout from '../Layout';
import AddUserComponent from '../../../components/crud/AddUserComponent';

const CustomerEdit = () => {
    
  return (
    <Layout>
        <AddUserComponent title={"Edit Customer"} userType={"Customer"}/>
    </Layout>
  )
}

export default CustomerEdit