import React from 'react'
import Layout from '../Layout';
import EditUserComponent from '../../../components/crud/EditUserComponent';

const CustomerEdit = () => {
    
  return (
    <Layout>
        <EditUserComponent title={"Edit Customer"} userType={"Customer"}/>
    </Layout>
  )
}

export default CustomerEdit