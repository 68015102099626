import React from 'react'
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/system/Stack';
import { CreateNewAdmin } from '../../services/AdminAPi';
import { CreateNewCompany } from '../../services/CompanyAPi';
import { EditCustomer } from '../../services/CutomerApi';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import {GetCustomerDetails} from '../../services/CutomerApi';
import DeleteCustomer from "./models/DeleteCustomer";

const emailRegEx  = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const userAddSchema = 
  Yup.object({
    emailId: Yup.string().required("Please enter email id.").matches(emailRegEx,'Email is not a valid email.'),
    firstName: Yup.string().required("Please enter First Name."),
    lastName: Yup.string().required("Please enter Last Name."),
    mobileNumber: Yup.string().required("Please enter Mobile Number."),
    address: Yup.string().required("Please enter Address."),
  })

const EditUserComponent = (props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const [ deleteOpen, setDeleteOpen ] = useState(false);
  const { id } = useParams();
  const [initValues, setInitValues] = useState(
    {
      firstName: "",
      lastName: "",
      companyName: "",
      emailId: "",
      mobileNumber: "",
      address: "",
      gstin: "",
    },
  );
  const navigate = useNavigate();

  useEffect(() => {
		async function fetchData() {
      if(id){
        const response = await GetCustomerDetails(id);
        if(response.status === 'success'){
            console.log(response);
          setInitValues(response.data[0]);
        }
      }
			
		}
		fetchData();
	}, [])

  const HandleDelete = async () =>{
    setDeleteOpen(true);
  }

  const formik = useFormik({
    enableReinitialize : true,
    initialValues: initValues,
    validationSchema: userAddSchema,
    onSubmit: values => {
      switch (props.userType) {
        case 'Admin':
            CreateNewAdmin(values).then((response)=>{
              if(response.status === 'success'){
                //redirect to list page
                navigate('/admin');
              }else{
                setIsError(true);
                setErrorMsg(response.message);
              }
            })
          break;
        case 'Company':
          CreateNewCompany(values).then((response) => {
            if(response.status === 'success'){
              //redirect to list page
              navigate('/company');
            }else{
              setIsError(true);
              setErrorMsg(response.message);
            }
          }) 
          break;
        case 'Customer':
          EditCustomer(values).then((response) => {
            console.log(response);
            if(response.status === 'success'){
              //redirect to list page
              navigate('/customer');
            }else{
              setIsError(true);
              setErrorMsg(response.message);
            }
          }) 
          break; 
        default:
          break;
      }
    },
  });
  return (
    <>
        <Card className="mt-3">
        <CardHeader
            title= {props.title}
        />
        <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          { isError ? (
            <Alert severity="error">{ errorMsg }</Alert>
          ) : ("")}
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>

              {/*
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="User Type"
                            name="userType"
                            >
                            <MenuItem value={10}>Admin</MenuItem>
                            <MenuItem value={20}>Company</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                */}
                <Grid item xs={6}>
                <TextField
                    margin="normal"
                    fullWidth
                    label="First Name *"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Last Name *"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                </Grid>
                { props.isCompany ? (
                <Grid  item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Company Name *"
                            name="companyName"
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                            helperText={formik.touched.companyName && formik.errors.companyName}
                        />
                </Grid>) : ("")
                }
                <Grid  item xs={6}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Email Id *"
                            name="emailId"
                            value={formik.values.emailId}
                            disabled="true"
                        />
                </Grid>
                <Grid  item xs={6}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Mobile Number *"
                            name="mobileNumber"
                            value={formik.values.mobileNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                            helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                        />
                </Grid>
                
                <Grid  item xs={6} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                        <TextField
                            id="outlined-multiline-flexible"
                            name="address"
                            label="Address *"
                            multiline
                            maxRows={6}
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                            />
                    </FormControl>
                </Grid>
                { props.isCompany? (
                <Grid  item xs={6}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="GST Number"
                            name="gstin"
                            value={formik.values.gstin}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.gstin && Boolean(formik.errors.gstin)}
                            helperText={formik.touched.gstin && formik.errors.gstin}
                        />
                </Grid>) : ("")
                }
                <Grid  item xs={12}>
                    <Stack spacing={2} direction="row">
                        <Button variant="contained" size="large" type="Submit">
                            {props.title}
                        </Button>
                        <Button onClick={HandleDelete} variant="contained" ml-2 color="error" size="large" type="Button">
                            Delete
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            </form>
        </Box>
        <DeleteCustomer setDeleteOpen={setDeleteOpen} deleteOpen={deleteOpen} customerId={id}/>
        </CardContent>
        </Card>
    </>
  )
}

export default EditUserComponent