import React from 'react'
import { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { ListDevice } from '../../services/DeviceApi';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import {SendBulkMessages} from "../../services/DeviceApi";
import readXlsxFile from 'read-excel-file'
import Alert from '@mui/material/Alert';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const BulkMessagesComponent = () => {

  const [openLoader, setOpenLoader] = useState(false);
  const [closeLoader, setCloseLoader] = useState(true);
  const [deviceList, setDeviceList] = useState([]);
  const [ excelFile, setExcelFile ] = useState(null);
  const [ senderDevice, setSenderDevice ] = useState("");
  const [ textMessage, setTextMessage] = useState("");
  const [ errors, setErrors ] = useState([]);
  const [ isSuccess, setIsSuccess ] = useState(0);
  const [ successMsg, setSuccessMsg ] = useState("");
  const [ isError, setIsError ] = useState(0);
  const [ errorMsg, setErrorMsg ] = useState("");

  useEffect(() => {
		async function fetchData() {
			const result = await ListDevice();
      if(result.status === 'success'){
        //console.log(result);
        setDeviceList(result.data)
      }
			
		}
		fetchData();
	}, [])

  const processFile = async (event) => {
    console.log(event.target.files[0])
    setExcelFile(event.target.files[0]);
  }

  const bulkSubmit = async () =>{
    //console.log(senderDevice);
    setOpenLoader(true);
    setCloseLoader(false);
    // first check SelectDevice
    if(senderDevice === ""){
      setErrors({senderDevice:"Please select device."})
      return false
    }
    // now check Text Message
    if(textMessage === ""){
      setErrors({textMessage:"Please enter text message."})
      return false
    }
    // now check file
    if(excelFile === null){
      setErrors({textMessage:"Please upload excel file."})
      return false
    }
    //https://www.npmjs.com/package/read-excel-file?activeTab=readme
    //Install above and read excel and send json data to the api
    let recieverMobList = await readXlsxFile(excelFile);
    let jsonData = {
      recieverList : recieverMobList,
      senderMob : senderDevice,
      msg : textMessage
    }
    // call api
    const response = await SendBulkMessages(jsonData);
    setOpenLoader(false);
    setCloseLoader(true);
    if(response.status === 'success'){
      setIsSuccess(1);
      setSuccessMsg(response.message);
    }else{
      setIsError(1);
      setErrorMsg(response.message);
    }
  }

  const handleDevice = async (event) => {
    let arr = errors;
    
    setSenderDevice(event.target.value);
  }

  const handleMessageChange = async (event) => {
    setTextMessage(event.target.value);
  }

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openLoader}
        close={closeLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        { isError ? (
          <Alert severity="error">{ errorMsg }</Alert>
        ) : ("")}
        { isSuccess ? (
          <Alert severity="success">{ successMsg }</Alert>
        ) : ("")}
        <CardHeader title="Bulk Messages"/>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={2}> </Grid>
            <Grid item xs={8}> 
              <Card>
                <CardHeader title="Send Bulk Messages using Excel"/>
                <CardContent>
                <Grid item xs={12}> 
                  <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    defaultValue=""
                    label="Select Device"
                    onChange= { (event)=>handleDevice(event)}
                    error={Boolean(errors.senderDevice)}
                    helperText={errors.senderDevice}
                  >
                    {deviceList.map((option) => (
                      <MenuItem key={option.id} value={option.mobileNumber}>
                        {option.mobileNumber}
                      </MenuItem>
                    ))}
                  </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Text Message Content"
                        name="tmc"
                        multiline
                        rows={10}
                        onChange = {(event)=>{ handleMessageChange(event)}}
                        />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload files
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => processFile(event)}
                        multiple
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12}  sx={{ mt: 1 }}>
                    <Button variant="outlined" color="warning" size="small" onClick={bulkSubmit}>Send Message</Button>
                  </Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}> </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default BulkMessagesComponent