import * as React from 'react';
import Button from '@mui/joy/Button';
import Box from '@mui/material/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const AnalyticsCard = (props) => {
  return (
    <Box sx={{ height: '100%' }} pb={5}>
    <Card variant="solid" color="primary" invertedColors >
      <CardContent orientation="horizontal">
        <CircularProgress size="lg" determinate value={90}>
          <AddShoppingCartIcon />
        </CircularProgress>
        <CardContent>
          <Typography level="body-md">{props.title}</Typography>
          <Typography level="h2">{props.value}</Typography>
        </CardContent>
      </CardContent>
    </Card>
    </Box>
  );
}

export default AnalyticsCard
