import React from 'react'
import Layout from '../Layout'
import ApiSettingComponent from '../../../components/customer/ApiSettingComponent'


const ApiSettings = () => {
  return (
    <Layout>
      <ApiSettingComponent/>
    </Layout>
  )
}

export default ApiSettings
