import React from 'react'
import Layout from '../Layout';
import AddUserComponent from '../../../components/crud/AddUserComponent';
const AdminAdd = () => {
  return (
    <Layout>
        <AddUserComponent title={"Add Admin"} userType={"Admin"}/>
    </Layout>
  )
}

export default AdminAdd