import React from 'react';
import Routers from "./routes/Routers"; 
import "./asset/css/App.css";
import { LoginProvider } from "./context/login/LoginContext";

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <LoginProvider>
          <Routers />
        </LoginProvider>
      </div>
    </div>
  );
}

export default App;
