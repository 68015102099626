import React from 'react'
import { useReducer, useState } from 'react';
import Logo from "../../asset/images/mymessagesLogo.png";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import * as Yup from "yup";
import { useFormik } from 'formik';
import useAuth from '../../hooks/useAuth';
import { loginReducer, initialState } from "../../context/login/LoginContext";
import { useNavigate } from 'react-router-dom';

const { Base64 } = require("js-base64");

const emailRegEx  = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const loginSchema = 
  Yup.object({
    email: Yup.string().required("Please enter email id.").matches(emailRegEx, 'Email is not a valid email.'),
    password: Yup.string().required("password can not be empty.")
  })

const LoginComponent = () => {
  const [loginState, dispatch] = useReducer(loginReducer, initialState);
  const [errorMsg, setErrorMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const { Login } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: values => {
      setIsError(false);
      setErrorMsg("");
      values.password = Base64.encode(values.password);
      Login(values).then((response)=>{
        if(response.data.status === 'success'){
          //const  appToken  = response.data.msg;
          let appToken = response.data.accessToken
          let id = response.data.user.id
          let role = response.data.user.roleId
          let email = response.data.user.email
          let companyId = response.data.user.companyId
          dispatch({
            type: "Login",
            payload: {
              appToken,
              id,
              role,
              email,
              companyId
            },
          });
          let navigateTo = '/dashboard';
          if(role === 'Sk9LRVI='){
            navigateTo = '/customer/devices';
          }
          setTimeout(() => { navigate(navigateTo);  }, 500);
        }else{
          // it must be an error
          //setting error
          setIsError(true);
          setErrorMsg(response.data.msg);
          dispatch({
            type: "ERROR",
            message : response.data.msg
          });
        }
      }).catch((error)=>{
          
      });
    },
  });
  
  
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          { isError ? (
            <Alert severity="error">{ errorMsg }</Alert>
          ) : ("")}
        
              <img
                srcSet={`${Logo}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${Logo}?w=248&fit=crop&auto=format`}
                alt="Logo"
                loading="lazy"
                style={{width: '200px'}}
              />

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              margin="normal"
              fullWidth
              label="Email Address"
              name="email"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </form>
          </Box>
        </Box>
      </Container>
    </>
  ) 
}

export default LoginComponent