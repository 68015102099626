import React from 'react'
import Layout from '../Layout';
import ListWithActionComponent from "../../../components/crud/ListWithActionComponent";
import ListDevices from '../../../components/AnalyticComponents/ListDevices';

const DevicesList = () => {
  
//GetCustomerSubscription
//<ListDevices data={subscription}/>
  return (
    <>
      <Layout>
        <ListDevices/>
            <ListWithActionComponent 
              title={"Device"}
              addLink={"/device/add"}
            />
      </Layout>
    </>
  )
}

export default DevicesList